<template>
  <div>
    <post-list :type="4"></post-list>
  </div>
</template>

<script>
import PostList from "@/components/post-list/post-list";
// todo 帮助中心样式优化
export default {
  name: "index",
  components: {PostList}
}
</script>

<style scoped>

</style>
